import { action, computed, observable } from 'mobx'

import { ITileset } from '~/client/graph'
import TilesetsStore from '~/client/src/shared/stores/domain/Tilesets.store'

import MapViewItemsSetupStore from './MapViewItemsSetup.store'

enum AcceptedFileTypes {
  zip = 'zip',
  geojson = 'geojson',
  geojsonld = 'geojsonld',
  geotiff = 'geotiff',
}

export default class TilesetsSetupStore {
  @observable public selectedTilesetId: string
  @observable public deletableTileset: ITileset = null

  public constructor(
    private readonly tilesetsStore: TilesetsStore,
    private readonly mapViewItemsSetupStore: MapViewItemsSetupStore,
  ) {}

  @computed
  public get selectedTileset(): ITileset {
    return this.tilesetsStore.byId.get(this.selectedTilesetId)
  }

  @action.bound
  public showDeleteDialog(): void {
    this.showDeleteConfirmationDialogByTileset(this.selectedTileset)
  }

  @action.bound
  public async selectTilesetById(
    id: string,
    shouldSaveSelectedItem: boolean = true,
  ): Promise<void> {
    const tileset = this.tilesetsStore.byId.get(id)
    this.selectTileset(tileset, shouldSaveSelectedItem)
  }

  @action.bound
  public async selectTileset(
    tileset: ITileset,
    shouldSaveSelectedItem: boolean = true,
  ): Promise<void> {
    if (!tileset) {
      return
    }

    if (shouldSaveSelectedItem) {
      await this.mapViewItemsSetupStore.saveSelectedMapViewItem()
    }

    this.deselectTileset()
    this.selectedTilesetId = tileset.id
  }

  @action.bound
  public deselectTileset(): void {
    this.selectedTilesetId = null
  }

  @action.bound
  public showDeleteConfirmationDialogByTileset(tileset: ITileset): void {
    this.deletableTileset = tileset
  }

  @action.bound
  public showDeleteConfirmationDialogByTilesetId(tilesetId: string): void {
    this.deletableTileset = this.tilesetsStore.list.find(
      s => s.id === tilesetId,
    )
  }

  @action.bound
  public hideTilesetDeleteConfirmDialog(): void {
    this.deletableTileset = null
  }

  @action.bound
  public applyTilesetDeleteConfirmDialog(): void {
    if (!this.deletableTileset) {
      return
    }

    this.deselectTileset()
    this.tilesetsStore.removeOne(this.deletableTileset.id)

    this.hideTilesetDeleteConfirmDialog()
  }

  public async uploadGeoTIFFToMapbox(file: File): Promise<void> {
    const splittedName = file.name.split('.')
    if (splittedName?.length <= 1) {
      return
    }
    const extention = splittedName.at(-1)
    if (
      Object.values(AcceptedFileTypes).includes(extention as AcceptedFileTypes)
    ) {
      await this.tilesetsStore.uploadTileset(file, file.name)
    }
  }
}
